import Shepherd from "shepherd.js";

const buttons = (tour) => [
  {
    text: "Anterior",
    action: () => tour.back(),
  },
  {
    text: "Próximo",
    action: () => tour.next(),
  },
];

const lastStepButtons = (tour) => [
  {
    text: "Anterior",
    action: () => tour.back(),
  },
  {
    text: "Terminar",
    action: () => tour.complete(),
  },
];

const globalTour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: "shepherd-tour",
    cancelIcon: {
      enabled: true,
    },
  },
});
globalTour.id = "globalTour";

/* ----------- Overridings ------------------- 
----------------------------------------------*/
const originalAddStep = Shepherd.Tour.prototype.addStep;
Shepherd.Tour.prototype.addStep = function (step) {
  let result = originalAddStep.call(this, step);

  // Adding any tour step to the globalTour.
  const globalStep = step;
  globalStep.buttons = buttons(globalTour);
  const exists = globalTour.steps.find((s) => s.id == step.id);
  if (!exists) {
    globalTour.addStep(step);
  }
  // Replace the global tour buttons according to the steps
  globalTour.steps.forEach((gstp, i) => {
    if (i == globalTour.steps.length - 1) {
      gstp.updateStepOptions({ buttons: lastStepButtons(globalTour) });
    } else {
      gstp.updateStepOptions({ buttons: buttons(globalTour) });
    }
  });

  this.steps.forEach((stp, i) => {
    if (i == this.steps.length - 1) {
      stp.updateStepOptions({ buttons: lastStepButtons(this) });
    } else {
      stp.updateStepOptions({ buttons: buttons(this) });
    }
  });

  return result;
};

const originalAddSteps = Shepherd.Tour.prototype.addSteps;
Shepherd.Tour.prototype.addSteps = function (steps) {
  if (!Array.isArray(steps)) {
    return originalAddSteps.call(this, steps);
  }

  let result = originalAddSteps.call(this, steps);

  // Adding any tour step to the globalTour.
  const newGlobalSteps = steps;
  newGlobalSteps.forEach((newStep) => {
    const exists = globalTour.steps.find((s) => s.id == newStep.id);
    if (!exists) {
      globalTour.addStep(newStep);
    }
  });
  // Replace the global tour buttons according to the steps
  globalTour.steps.forEach((gstp, i) => {
    if (i == globalTour.steps.length - 1) {
      gstp.updateStepOptions({ buttons: lastStepButtons(globalTour) });
    } else {
      gstp.updateStepOptions({ buttons: buttons(globalTour) });
    }
  });

  // Replace the step buttons for the current tour being instantiated.
  this.steps.forEach((stp, i) => {
    if (i == this.steps.length - 1) {
      stp.updateStepOptions({ buttons: lastStepButtons(this) });
    } else {
      stp.updateStepOptions({ buttons: buttons(this) });
    }
  });

  return result;
};

Shepherd.Tour.prototype.back = function () {
  let index = this.steps.indexOf(this.currentStep) - 1;
  if (this.currentStep.options.clickBack?.element) {
    const query = this.currentStep.options.clickBack.element;
    const el = document.querySelector(query);
    if (el) {
      el.click();
    }
  }
  if (this.currentStep.options.clickBack?.skipToStep) {
    const newStepId = this.currentStep.options.clickBack.skipToStep;
    this.hide();
    index = this.steps.findIndex((step) => step.id == newStepId);
  }
  this.show(index, false);
};

Shepherd.Tour.prototype.next = function () {
  let index = this.steps.indexOf(this.currentStep);
  if (index === this.steps.length - 1) {
    this.complete();
  } else {
    let isDropdown = false;
    if (this.currentStep && this.currentStep.options.clickNext?.skipToStep) {
      const newStepId = this.currentStep.options.clickNext.skipToStep;
      this.hide();
      index = this.steps.findIndex((step) => step.id == newStepId) - 1;
    }
    if (this.currentStep && this.currentStep.options.clickNext?.element) {
      const query = this.currentStep.options.clickNext.element;
      const el = document.querySelector(query);
      if (el) {
        isDropdown =
          el.getAttribute("data-toggle") == "dropdown" || el.classList.contains("dropdown");
        if (isDropdown) {
          setTimeout(() => {
            el.click();
          }, 10);
        } else {
          el.click();
        }
      }
    }
    if (isDropdown) {
      setTimeout(() => {
        this.show(index + 1, true);
      }, 20);
    } else {
      this.show(index + 1, true);
    }
  }
};

const allTours = [];
export const newTour = (...args) => {
  // Create a new instance of Shepherd.Tour
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "shepherd-tour",
      cancelIcon: {
        enabled: true,
      },
    },
    ...args,
  });

  // Check if 'tourName' property is present and set it as the Tour id
  if (args.length > 0 && args[0].hasOwnProperty("tourName")) {
    const tourName = args[0].tourName;
    tour.id = tourName;
  }

  allTours.push(tour);
  return tour;
};

export const removeTour = (tourId) => {
  const index = allTours.findIndex((tour) => tour.id === tourId);
  const removingTour = allTours[index];
  removingTour.cancel();
  if (index !== -1) {
    allTours.splice(index, 1);
  }
  return removingTour;
};

const shepherdKey = "$shepherd";
const install = function installVueShepherd(app) {
  if (install.installed) return;
  install.installed = true;

  app.config.globalProperties[shepherdKey] = {
    newTour,
    removeTour,
    allTours,
    globalTour,
    Shepherd,
  };
};

const plugin = { install };

// To auto-install on non-es builds, when vue is found
// eslint-disable-next-line no-redeclare
/* global window, global */
if ("false" === process.env.ES_BUILD) {
  let GlobalVue = null;
  if (typeof window !== "undefined") {
    GlobalVue = window.Vue;
  } else if (typeof global !== "undefined") {
    GlobalVue = global.Vue;
  }
  if (GlobalVue) {
    GlobalVue.use(plugin);
  }
}

// Default export is library as a whole, registered via Vue.use()
export default plugin;
