import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import { createI18n } from "vue-i18n";
import mitt from "mitt";
const emitter = mitt();
import Notifications from "@kyvg/vue3-notification";
import TourPlugin from './services/tourPlugin'
import "shepherd.js/dist/css/shepherd.css";

// Configuring VueShepherd


// Configuring i18n
let messages = {};
import br from "./locales/br.json";
import en from "./locales/en.json";
import es from "./locales/es.json";

messages = { br: br, en: en, es: es };
const i18n = createI18n({
  locale: "br",
  messages,
});

// Configuring Jquery
import jQuery from "jquery";
global.jQuery = jQuery;
global.jquery = jQuery; // jquery lowercase was the solution for me
global.$ = jQuery;

// Creating VueApp
let VueApp = createApp(App);
VueApp.config.globalProperties.$mitt = emitter;
VueApp.use(store)
    .use(i18n).use(router)
    .use(VueAxios, axios)
    .use(Notifications)
    .use(TourPlugin);

export default VueApp.mount("#app");
