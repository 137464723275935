import api from './request';
import utils from './utils';
import { notify } from "@kyvg/vue3-notification";



export default {

  /**
   * Returns APPNAME
   *
   * @returns {string}
   */
  getAppName() {
    return 'easybusi';
  },

  /**
   * Returns default main host
   *
   * @returns {string}
   */
  getMainHost() {
    return 'easybusi.com.br';
  },


  /**
   *
   * @returns {string}
   */
  getTenantName() {
    let fullname = location.hostname;
    let names = fullname.split('.');
    return names[0];
  },


  async setStorageItem(key, value) {
    return Promise.resolve().then(function () {
      window.localStorage.setItem(key, value);
      window.dispatchEvent(new CustomEvent('setStorageItem', {
        detail: { [key]: value },
      }))
    });
  },

  getStorageItem(key) {
    return new Promise((resolve) => {
      resolve({
        data: window.localStorage.getItem(key)
      });
    });
  },

  /**
   * Fetch the tenant data from server or bring it from cache
   *
   * @returns string
   */
  getCachedTenant() {
    return this.getStorageItem('tenant-' + this.getTenantName());
  },

  async getUpdatedTenant() {
    return await api.sendUnsafeRequest('get', '/tenantinfo', '')
  },

  async getTenantData() {
    let tenantData = await this.getCachedTenant();
    if (tenantData.data == null) {
      tenantData = await this.getUpdatedTenant();
      await this.setStorageItem('tenant-' + tenantData.data.alias, JSON.stringify(tenantData.data));
      return tenantData.data;
    }
    return JSON.parse(tenantData.data);
  },

  async logout() {
    window.localStorage.removeItem('user');
  },

  getStuff(key) {
    return JSON.parse(window.localStorage.getItem(key));
  },

  getRouteAuthorization(route) {
    return api.sendRequest('get', '/authorization/' + route, '');
  },


  async fetchTenantData() {
    let tenantData = await this.getCachedTenant();
    if (typeof tenantData !== "object") {
      tenantData = JSON.parse(tenantData);
    }
    if (tenantData == null || tenantData.alias != this.getTenantName() || tenantData.alias == undefined) {
      let tenantDataUpdated = await api.sendUnsafeRequest('get', '/tenantinfo', '').then((res) => {
        this.setStorageItem('tenant-' + this.getTenantName(), JSON.stringify(res.data));
        return res.data;
      });
      return tenantDataUpdated;
    } else {
      return tenantData;
    }
  },

  storeUser() {

  },

  getMenu() {

  },

  getForms() {

  },

  authenticateIntegration(driver) {
    const user = utils.getUserId();
    const route = `/authenticate-integration?driver=${driver}&user=${user}`;
    const request = api.sendUnsafeRequest('get', route);
    request.then((success) => {
      window.open(success.data);
    }).catch((error) => {
      notify({
        type: 'error',
        text: error.response.data.message,
      })
    })
  },

  integration(integrationApi, params = {}) {
    const user = utils.getUserId();
    params.user = user;
    params = JSON.stringify(params);
    const route = `/integration?api=${integrationApi}&params=${params}`;
    const request = api.sendUnsafeRequest('get', route);
    request.then((success) => {
      return success.data;
    }).catch((error) => {
      notify({
        type: 'error',
        text: error.response.data.message,
      })
    })
  }

  // authorizationCode(data) {
  //     let form = document.createElement('form');
  //     form.setAttribute('method', 'GET');
  //     form.setAttribute('action', data.auth_endpoint);
  //     form.setAttribute('target', '_blank');

  //     // Parameters to pass to OAuth 2.0 endpoint.
  //     var params = {
  //         'client_id': data.client_id,
  //         'redirect_uri': data.redirect_uri,
  //         'response_type': data.response_type,
  //         'scope': data.scopes,
  //         'include_granted_scopes': 'true',
  //     };

  //     // Add form parameters as hidden input values.
  //     for (var p in params) {
  //         var input = document.createElement('input');
  //         input.setAttribute('type', 'hidden');
  //         input.setAttribute('name', p);
  //         input.setAttribute('value', params[p]);
  //         form.appendChild(input);
  //     }

  //     // Add form to page and submit it to open the OAuth 2.0 endpoint.
  //     document.body.appendChild(form);
  //     form.submit();
  // }

  //
  //
  //     async checkLogin() {
  //         return true;
  //         let validToken = this.sendRequest('get', '/resource', '');
  //         validToken.then(function (res) {
  //             return true;
  //         }).catch(function (err) {
  //             window.localStorage.removeItem('user');
  //             // notification.throw(err,'SESSION_EXPIRED');
  //             return false;
  //         });
  //     }
  //
  // }

  //
  //     appName = 'applatform';
  //     mainHost = 'applatform.com.br';
  //

  //
  //     user = window.localStorage.getItem('user');
  //     userObj = JSON.parse(user);
  //     $token = userObj.token
  //
  //
  //     checkToken = function () {
  //         let check = request.sendRequest()
  //         check.then(function (response) {
  //             console.log(response);
  //             console.log('response');
  //         })
  //             .catch(function (error) {
  //                 notification.throw(error);
  //                 window.localStorage.removeItem('user');
  //             })
  //     }
  //
  //
  // //Login Request
  //     loginUser = function (data) {
  //         let login = api.post('/oauth', data);
  //         login.then(function (response) {
  //             let user = response.user;
  //             window.localStorage.setItem('user', JSON.stringify(response.user));
  //         })
  //             .catch(function (error) {
  //                 notification.throw(error);
  //             })
  //     }


  //Forget password Request

  //Reset password Request

  //Two factor Request

}