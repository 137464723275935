import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import user from "@/services/user";
import service from "@/services/auth";
import api from "@/services/request";


const routes = [
    //Admin Login screen
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue')
  },
  {
    path: '/password-recovery/:resetPasswordHash?',
    name: 'PasswordRecovery',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Recover.vue')
  },
    //System internal routes
  // {
  //   path: '/:route/edit/:edit?',
  //   name: 'InternalEdit',
  //   component: () => import(/* webpackChunkName: "system" */ '../views/Default.vue')
  // },
  {
    path: '/:route/detail/:detail?',
    name: 'InternalDetail',
    component: () => import(/* webpackChunkName: "system" */ '../views/Default.vue')
  },
  {
    path: '/:route/new/:new?',
    name: 'InternalDetail',
    component: () => import(/* webpackChunkName: "system" */ '../views/Default.vue')
  },
  {
    path: '/:route/:id?',
    name: 'InternalFetch',
    component: () => import(/* webpackChunkName: "system" */ '../views/Default.vue')
  },
  {
    path: '/:route',
    name: 'InternalIndex',
    component: () => import(/* webpackChunkName: "system" */ '../views/Default.vue'),
    props: true,
  },
  {
    path: '/:route/:mode/:id',
    name: 'InternalMode',
    component: () => import(/* webpackChunkName: "system" */ '../views/Default.vue')
  },

  //External Website home
  {
    path: '/',
    name: 'ExternalIndex',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
  },
  //External Website home
  {
    path: '/page/:page',
    name: 'Site',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
  },
  //External Website home
  {
    path: '/page/:page/:template/id=:id',
    name: 'ExternalPages',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
  },
  //External Website home
  {
    path: '/page/:page/:template/category=:category/subcategory=:subcategory',
    name: 'ExternalPagesTwo',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition;
    }
  },
})

router.beforeEach((to, from, next) => {
  // Default public pages for all sites.
  const defaultPublicRoutes = ['/login', '/', '/robots.txt'];

  // Get Tenant site definitions including public and private pages.
  const sitePublicRoutes = window.localStorage.getItem('user') != null ? window.localStorage.getItem('user') : [];

  // Check if route is public accessible.
  const isPublicRoute = defaultPublicRoutes.includes(to.params.route) || sitePublicRoutes.includes(to.params.route);
  if(isPublicRoute || to.params.route == undefined){
    return next();
  }

  //Check if there is an active user session, otherwise redirect to login.
  const userSession = user.getUserSession();
  if(userSession == null && to.name !== 'Login' && to.name !== 'Index'){
    //TODO end user login or employee login? how to proper deal? end user being a modal to other url?
    return next('/login');
  }

  // Deal with private routes and user session.
  const authorization = service.getRouteAuthorization(to.params.route);

  authorization.then((res)=>{
    // Go to next route.
    if (res.data) {
      return next();
    }

    if (to.params.route == 'download'){
      let baseUrl = api.getBaseUrl();
      let query = to.query;
      //build URL with query params
      let fullURL = baseUrl + '/download?';
      for (const [key, value] of Object.entries(query)) {
        fullURL += key + '=' + value + '&';
      }
      //remove last &
      fullURL = fullURL.slice(0, -1);
      //redirect to download
      window.location.href = fullURL;
    }

    next('/home');
    notify({
      type: 'info',
      text: 'Você não tem acesso a este recurso.'
    });

  }, (err) => {
    console.error(err.response);
    if(err.response.status == 401){
      notify({
        type: 'info',
        text: 'Sessão expirada'
      });
      user.logout();
      return next('/login');
    }
    // Generic error
    notify({
      type: 'error',
      text: 'Ocorreu um erro inesperado: '+err.response.statusText
    });
    return;
  });
})


export default router
