import axios from 'axios';

var headers;

export default  {

    headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json'
    },

    /**
     * Returns the default base URL for the API services
     *
     * @returns {string}
     */
    getBaseUrl(){
        if (location.hostname.indexOf('localhost') != -1) {
            return location.protocol+'//'+location.hostname;
        }
        return location.protocol+'//'+location.hostname+'/api';
    },

    /**
     * Returns the default headers for the API services
     *
     * @returns {}
     */
    getHeaders(needAuth = false){
        const headers = { 'Content-Type': 'multipart/form-data' };

        if (needAuth) {
            return { ...headers, ...this.getAuthorizationTokenHeader() };
        }

        return { ...headers };
    },

    /**
     * Returns the default request headers
     *
     * @returns {}
     */
    getDefaultHeaders(){
        return this.headers;
    },

    /**
     * Get Authorization Token header for authenticated requests.
     * @param token
     * @returns {{Authorization: string}}
     */
    getAuthorizationTokenHeader(token){
        if (token == null) {
            let userSession = window.localStorage.getItem('user');
            let userObject = JSON.parse(userSession);
            token = userObject.token;
        }

        return {'Authorization':'Bearer ' + token};
    },

    /**
     * Set the Authroization header to the api object.
     * @param token
     */
    setAuthorizationToken(token){
        let auth = this.getAuthorizationTokenHeader(token);
        this.headers = Object.assign(this.headers, auth);
    },

    /**
     * Retrieve axios object.
     * @returns {Promise<AxiosInstance>}
     */
    async getInternalAPIAxios(){
        return axios.create({
            baseURL: this.getBaseUrl(),
            headers: this.headers,
        });
    },


    /**
     *
     * SendRequest - Sends a request to backend servers
     *
     * @param method - http method
     * @param api - internal api route or external complete api path
     * @param data - payload data or queryString
     * @returns {Promise<*>}
     */
    async sendRequest(method,api,data){
        this.setAuthorizationToken();
        var requester = await this.getInternalAPIAxios();
        if (api.indexOf('https://')) {
            //change requester value later
            //requester = this.getExternalAPIAxios();
        }

        let config = {'url':api,'method':method,'data':data}
        return await requester.request(config);
    },

    /**
     *
     * SendRequest - Sends a request to backend servers without auth token
     *
     * @param method - http method
     * @param api - internal api route or external complete api path
     * @param data - payload data or queryString
     * @returns {Promise<*>}
     */
    async sendUnsafeRequest(method,api,data){
        var requester = await this.getInternalAPIAxios();
        if (api.indexOf('https://')) {
            //change requester value later
            //requester = this.getExternalAPIAxios();
        }

        let config = {'url':api,'method':method,'data':data}
        return await requester.request(config);
    },


}

